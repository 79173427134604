import Vue from 'vue'
import App from './App.vue'
import './styles/global.less'
import router from './router'
import '@/permission'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import './styles/util.less'
import "./eventBus"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { resourseUrl } from "@/utils/config"
import xss from 'xss';

Vue.prototype.baseUrl = resourseUrl
Vue.prototype.xss = xss
// 注册全局过滤器
import {formatDate} from '@/utils/formatDate.js'
Vue.filter('vFormatDate',formatDate)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
