<template>
    <div class="bottommenu-container">
        <div class="bottommenu-box acea-row">
            <!-- 成员信息 -->
            <div class="acea-row">
                <div class="member-title">成员信息</div>
                <div class="acea-row">
                    <div v-for="(column,colIndex) in memberList" :key="colIndex" class="member-col">
                        <div v-for="item in column" :key="item.id" class="member-item" @click="openSite(item.link)">{{item.name}}</div>
                    </div>
                </div>
            </div>
            <!-- 二维码 -->
            <div class="acea-row code-box" v-if="configInfo">
                <div class="code-item" v-show="configInfo.wx_gzh_qrcode">
                    <img :src="configInfo.wx_gzh_qrcode" alt="">
                    <div>微信公众号二维码</div>
                </div>
                <div class="code-item" v-show="configInfo.wx_xcx_qrcode">
                    <img :src="configInfo.wx_xcx_qrcode" alt="">
                    <div>微信小程序码</div>
                </div>
            </div>
        </div>
        <div class="info-container">
            <span>{{ configInfo ? configInfo.site_copyright : '' }}</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">{{ configInfo ? configInfo.site_record_number : '' }}</a>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        data() {
            return {
                memberList:[
                    [{
                        id: 1,
                        name: "发展和改革局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/fzhggj/zfxxgkzn/'
                    },{
                        id: 8,
                        name: "财政局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/czj/zfxxgkzn/'
                    },{
                        id:15,
                        name:'水利局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/slj/zfxxgkzn/'
                    },{
                        id:22,
                        name:'统计局 ',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/tjj/zfxxgkzn/'
                    }],
                    [{
                        id: 2,
                        name: "工业和信息化局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/jjhxxhj/zfxxgkzn/'
                    },{
                        id:9,
                        name:'应急管理局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/ajj/zfxxgkzn/'
                    },{
                        id:16,
                        name:'农业农村局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/nyj/zfxxgkzn/'
                    },{
                        id:23,
                        name:'生态环境局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/hbj/zfxxgkzn/'
                    }],
                    [{
                        id: 3,
                        name: "教育局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/jyj/zfxxgkzn/'
                    },{
                        id:10,
                        name:'人力资源和社会保障局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/rlzyhshbzj/zfxxgkzn/'
                    },{
                        id:17,
                        name:'林业局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/lyj/zfxxgkzn/'
                    },{
                        id:24,
                        name:'市场监督管理局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/scjdglj/zfxxgkzn/'
                    }],
                    [{
                        id: 4,
                        name: "科学技术局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/kjhzscqj/zfxxgkzn/'
                    },{
                        id:11,
                        name:'自然资源局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/gtzyj/zfxxgkzn/'
                    },{
                        id:18,
                        name:'商务局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/swj/zfxxgkzn/'
                    },{
                        id:25,
                        name:'税务局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/shwj/zfxxgkzn/'
                    }],
                    [{
                        id: 5,
                        name: "公安局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/gaj/zfxxgkzn/'
                    },{
                        id:12,
                        name:'住房和城乡建设局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/zfhcxjsj/zfxxgkzn/'
                    },{
                        id:19,
                        name:'文化体育和旅游局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/wtxj/zfxxgkzn/'
                    },{
                        id:26,
                        name:'信访局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/xfj/zfxxgkzn/'
                    }],
                    [{
                        id: 6,
                        name: "民政局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/mzj/zfxxgkzn/'
                    },{
                        id:13,
                        name:'城市管理局 ',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/szgysyglj/zfxxgkzn/'
                    },{
                        id:20,
                        name:'卫生健康局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/wshjhsyj/zfxxgkzn/'
                    },{
                        id:27,
                        name:'退役军人事务局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/tyjrswj/zfxxgkzn/'
                    }],
                    [{
                        id: 7,
                        name: "司法局",
                        link: 'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/sfj/zfxxgkzn/'
                    },{
                        id:14,
                        name:'交通运输局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/jtysj/zfxxgkzn/'
                    },{
                        id:21,
                        name:'审计局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/sjj/zfxxgkzn/'
                    },{
                        id:28,
                        name:'医疗保障局',
                        link:'http://www.nanan.gov.cn/zwgk/zfxxgkzl/bmzfxxgk/ylbzj/zfxxgkzn/'
                    }]
                ]
            }
        },
        computed:{
            ...mapState("settings",['configInfo'])
        },
        methods:{
            openSite(link){
                window.open(link,'_blank')
            }
        }
    }
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
.bottommenu-container {
    width: 100%;
    min-width: 1280px;
    background: #1C232C;
    position: relative;
    margin-top: 40px;
    .bottommenu-box{
        width: 1280px;
        margin: 0 auto;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        padding-top: 85px;
        background: #1C232C;
        .member-title{
            width: 92px;
        }
        .member-col{
            border-right: 1px solid #4D6887;
            .member-item {
                font-size: 14px;
                color: #78808d;
                margin: 0 20px 16px;
                cursor: pointer;
                &:hover{
                    color: #fff;
                }
            }
        }
        .code-box{
            align-items: flex-end;
            margin-bottom: 20px;
            justify-content: flex-end;
            flex: 1;
            .code-item {
                font-size: 12px;
                width: 100px;
                text-align: center;
                img {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
    .info-container {
        text-align: center;
        font-size: 14px;
        color: #4D6887;
        margin: 0 auto;
        padding-top: 38px;
        padding-bottom: 20px;
        background: #1C232C;
        width: 1280px;
        text-align: center;
        span{
            margin-right: 32px;
        }
        a {
          margin-right: 32px;
        }
    }
}
</style>