<template>
  <div>
    <div class="topmenu-container" :class="{'fixed':fixed}">
      <div class="topmenu-box acea-row row-between row-middle">
        <div class="left-menu acea-row row-middle">
          <!-- logo -->
          <div class="logo-container" @click="toOtherPage('/')">
            <img v-show="!fixed" src="../../assets/logo1.png" alt="" style="height:100%;width:100%;">
            <img v-show="fixed" src="../../assets/logo1.png" alt="" style="height:100%;width:100%;">
          </div>
          <!-- menu -->
          <div class="menulist-container">
              <ul class="acea-row row-middle">
                  <router-link
                      class="menu-item"
                      v-for="item in menuList"
                      :key="item.value"
                      :exact="item.exact"
                      :to="{ name: item.name }"
                      active-class="active"
                      exact-active-class=""
                  >
                      {{ item.title }}
                  </router-link>
              </ul>
          </div>
        </div>
        <div class="right-menu acea-row row-middle">
          <template v-if="showLogin">
            <div class="login-btn" @click="toLogin">登录</div>
            |
            <div class="register-btn" @click="toRegister">立即注册</div>
          </template>
          <div class="acea-row row-middle" v-else>
            <div class="user-box acea-row row-middle">
              <img class="avatar" :src="userInfo && userInfo.avatar ? userInfo.avatar : defaultAvatar" alt="">
              {{ userInfo && userInfo.name }}
            </div>
            <div class="logout" @click="toLogout">退出登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import { mapState } from 'vuex'
export default {
  props:{
    top:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      menuList: [
        {
          value: 0,
          title: "首页",
          name: "Home",
          exact: false, 
        },
        {
          value: 1,
          title: "综合服务",
          name: "Comprehensive",
          exact: false, //激活状态是否要精确匹配
        },
        {
          value: 2,
          title: "选品中心",
          name: "Selection",
          exact: false,
        },
        {
          value: 3,
          title: "一键开店",
          name: "OpenShop",
          exact: false,
        },
        {
          value: 4,
          title: "电商学院",
          name: "ECommerce",
          exact: false,
        },
        {
          value: 5,
          title: "个人中心",
          name: "User",
          exact: false,
        }],
      showLogin:true,
      token:'',
      defaultAvatar:require('@/assets/avatar.png')
    };
  },
  mounted(){
    this.token = getToken() 
  },
  computed:{
    fixed(){
      const isFixed = this.$route.meta.fixed //顶部菜单是否需要固定定位
      return +this.top > 110 || isFixed
    },
    ...mapState("user",['userInfo'])
  },
  watch:{
    token(){
      this.showLogin = this.token ? false : true
    }
  },
  methods: {
    toLogout(){
      this.$confirm('确定退出登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout').then(res=>{
          this.showLogin = true
          if(this.$route.path === '/user/index') {
            this.$router.replace({path:'/'})
          }
        })
      }).catch(() => {});
    },
    toOtherPage(path){
      this.$router.push({
        path
      })
    },
    toRegister() {
      this.$router.push(`/register?redirect=${this.$route.fullPath}`)
    },
    toLogin() {
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
.topmenu-container {
    width: 100%;
    height: 75px;
    box-sizing: border-box;
    position: fixed;
    z-index: 1000;
    color: #fff;
    &.fixed{
      background: #4e8efc;
      // color: #000;
      top: 0;
    }
}
.topmenu-box {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
}
.logo-container {
  width: 214px;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  margin-right: 111px;
  cursor: pointer;
}
.menulist-container {
  font-size: 16px;
}
.menu-item { 
    width: 64px;
    font-weight: 700;
    margin-right: 61px;
    cursor: pointer;
    text-align: center;
}
.menu-item:last-child{
    margin-right: 0;
}
.menu-item.active {
  border-bottom: 3px solid;
  padding-bottom: 5px;
  height: 100%;
  box-sizing: border-box;
}
.right-menu {  
  font-size: 16px;    
  font-weight: 700;
  cursor: pointer;
  .login-btn {
    margin-right: 13px;
  }
  .register-btn {
    margin-left: 13px;
  }
  .avatar {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    margin-right: 7px;
  }
  .logout {
    font-weight: 400;
    margin-left: 14px;
    &:hover {
      color: @primary;
      border-bottom: 1px solid @primary;
    }
  }
}

</style>