import request from '@/utils/request'
// 获取首页banner图
export function getBanner(params){
    return request({
        url: '/api/common/getBanner',
        method: 'get',
        params
    })
}

// 上传图片
export function uploadFile(data){
    return request({
        url: '/api/common/uploadImage',
        method: 'post',
        data
    })
}
// 获取配置
export function getConfig(){
    return request({
        url:'/api/common/getConfig',
        method: 'get'
    })
}