import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import { titleController } from "@/utils"
NProgress.configure({ showSpinner: false }) // NProgress Configuration


// 路由前置守卫
router.beforeEach(async(to, from, next) => {
    // start progress bar
    NProgress.start()

    const hasGetUserInfo = store.getters.user //获取用户信息
    const hasToken = getToken()
    // 是否有token
    if(hasToken) {
        if (to.path === '/login' || to.path === '/register' || to.path === '/registerM') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done()
        }else {
            // 看一下是否用户信息
            if (hasGetUserInfo) {
                next()
            } else {
                // 没有用户信息 ，但是有token => 获取cookie里的token 获取用户信息
                try {
                    // get user info
                    await store.dispatch('user/getInfo')
                    next()
                    NProgress.done()
                } catch (error) {
                    // remove token and go to login page to re-login
                    await store.dispatch('user/resetToken')
                    Message.error('登录已过期，请重新登录')
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        }
    }else {
        // has no token
        if (to.meta.auth) {
            //  other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
        } else {
            // in the free login, go directly
            next()
        }
        NProgress.done()
    }
})

router.afterEach((to, from) => {
    NProgress.done()
    if (to.meta.title) {
      titleController.setRouteTitle(to.meta.title)
    }
})